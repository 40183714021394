import React, { useState, useCallback } from 'react';
import moment from "moment";
import { useTranslation } from "react-i18next";
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';

import './CustomDateRangePicker.css';

export default function CustomDateRangePicker(props) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
 

  const handleCallback = useCallback(
    (startDate, endDate) => {
      console.log("Dates selected:", startDate, endDate);
      startDate = moment(startDate);
      endDate = moment(endDate);
      setStartDate(startDate);
      setEndDate(endDate);
      props.setStartAndEndDate(startDate, endDate);
      if (props.onDateRangeChange) {
        props.onDateRangeChange(startDate, endDate);
      }
    },
    [props.onDateRangeChange]
  );

  return (
    <>
      <div className="row">
        <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
          <div className="card crm-data-card">
            <div className="text-right"></div>
            <div className="card-body animated fadeIn fast">
              <div className="row text-center d-flex align-items-center justify-content-between">
                <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"}>
                 
                <>
                <DateRangePicker
                  initialSettings={{
                    startDate: startDate ? startDate.toDate() : moment().toDate(),
                    endDate: endDate ? endDate.toDate() : moment().toDate(),
                  }}
                  onEvent={(e, picker) => {
                    handleCallback(picker.startDate, picker.endDate);
                    if (props.selectedTimeMode !== 5) {
                      props.setSelectedTimeMode(5);
                    }
                  }}
                >
                  <button
                    className={`button-style choose-date-button ${props.selectedTimeMode === 5 ? 'selected' : ''}`}
                  >
                      {t("Choose Date")}
                  </button>
                </DateRangePicker>
              </>


                  
                </div>
                <div className="d-inline-block">
                  {props.selectedTimeMode === 4 && <span className="button-style-clicked"> {t("Beginning of the year")} </span>}
                  {props.selectedTimeMode !== 4 && (
                    <a
                      href="#"
                      onClick={() => {
                        props.setSelectedTimeMode(4);
                        let startDate = moment().subtract(1, 'year').startOf('month').toDate();
                        let endDate = moment().endOf('month').toDate();
                        props.setStartAndEndDate(startDate, endDate);
                      }}
                      className={`button-style ${props.selectedTimeMode === 4 ? 'selected' : ''}`}
                    >
                      <span> {t("Beginning of the year")} </span>
                    </a>
                  )}

                  {props.selectedTimeMode === 3 && <span className="button-style-clicked">{t("Last 3 months")}</span>}
                  {props.selectedTimeMode !== 3 && (
                    <a
                      href="#"
                      onClick={() => {
                        props.setSelectedTimeMode(3);
                        let startDate = moment().subtract(3, 'month').startOf('month').toDate();
                        let endDate = moment().endOf('month').toDate();
                        props.setStartAndEndDate(startDate, endDate);
                      }}
                      className={`button-style ${props.selectedTimeMode === 3 ? 'selected' : ''}`}
                    >
                      <span> {t("Last 3 months")} </span>
                    </a>
                  )}

                  {props.selectedTimeMode === 2 && <span className="button-style-clicked"> {("This month")} </span>}
                  {props.selectedTimeMode !== 2 && (
                    <a
                      href="#"
                      onClick={() => {
                        props.setSelectedTimeMode(2);
                        let startDate = moment().startOf('month').toDate();
                        let endDate = moment().endOf('month').toDate();
                        props.setStartAndEndDate(startDate, endDate);
                      }}
                      className={`button-style ${props.selectedTimeMode === 2 ? 'selected' : ''}`}
                    >
                      <span> {t("This month")} </span>
                    </a>
                  )}

                  {props.selectedTimeMode === 1 && <span className="button-style-clicked">{t("Last week")} </span>}
                  {props.selectedTimeMode !== 1 && (
                    <a
                      href="#"
                      onClick={() => {
                        props.setSelectedTimeMode(1);
                        let startDate = moment().subtract(1, 'weeks').startOf('week').toDate();
                        let endDate = moment().subtract(1, 'weeks').endOf('week').toDate();
                        props.setStartAndEndDate(startDate, endDate);
                      }}
                      className={`button-style ${props.selectedTimeMode === 1 ? 'selected' : ''}`}
                    >
                      <span> {t("Last week")} </span>
                    </a>
                  )}

                  {props.selectedTimeMode === 0 && <span className="button-style-clicked">{t("Current week")}</span>}
                  {props.selectedTimeMode !== 0 && (
                    <a
                      href="#"
                      onClick={() => {
                        props.setSelectedTimeMode(0);
                        let startDate = moment().startOf('week').toDate();
                        let endDate = moment().endOf('week').toDate();
                        props.setStartAndEndDate(startDate, endDate);
                      }}
                      className={`button-style ${props.selectedTimeMode === 0 ? 'selected' : ''}`}
                    >
                      <span> {t("Current week")}</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

