import React, {useEffect, useState} from 'react';
import './Notifications.css';
import NotificationTable from '../../components/NotificationTable/NotificationTable';

export default function Notifications(props) {
    const [currentUser, setCurrentUser] = useState();

    useEffect(() => {
        setCurrentUser(JSON.parse(localStorage.getItem("me")));
    }, []);

    return (

        <>
            {currentUser && <NotificationTable currentUser={currentUser}/>}
        </>);
}